import React from 'react';
import Typography, { TypographyProps } from '../../../Typography/Typography';

const ContentfulGroupingHeading = ({
  children,
  fontWeight = 'bold',
  marginBottom = 0,
  tag = 'h3',
  type = 'h2',
  ...props
}: TypographyProps) => {
  if (!children) {
    return null;
  }

  return (
    <Typography fontWeight={fontWeight} marginBottom={marginBottom} tag={tag} type={type} {...props}>
      {children}
    </Typography>
  );
};

export default ContentfulGroupingHeading;
