import Flex from 'components/Flex/Flex';
import { FlexDirection, FlexItem, Spacer } from 'constants/styling';
import { ReactNode } from 'react';

interface ContentfulGroupingWrapperProps {
  alignItems?: FlexItem;
  children?: ReactNode;
  className?: string;
  flexDirection?: FlexDirection;
  gap?: Spacer;
}

const ContentfulGroupingWrapper = ({
  alignItems,
  children,
  className,
  flexDirection = 'column',
  gap = 75,
}: ContentfulGroupingWrapperProps) => {
  if (!children) {
    return null;
  }

  return (
    <Flex flexDirection={flexDirection} gap={gap} className={className} alignItems={alignItems}>
      {children}
    </Flex>
  );
};

export default ContentfulGroupingWrapper;
